/* eslint-disable react/no-unknown-property */
/* eslint-disable react/require-default-props */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import * as React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { css, SerializedStyles } from "@emotion/react"
import { useLocation } from "@reach/router"
import Media from "components/Media"
import { IJsonContentfulField } from "common/types"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { CategoryProps } from "components/Category"
import ListItem, { IListItemProps } from "components/ListItem.tsx"
import { CheckMarkRoundedIcon } from "components/icons"
import { getColorScheme, IColorScheme, theme as themeCommon } from "common/colorScheme"
import { BottomContainer } from "pages/articles"
import { H5, SpanH5, getColorStyles, H3 } from "../../headings"
import Button, { ButtonProps, ButtonBase } from "../../button"
import { useSectionTheme } from "../SectionThemeContext"
import { IThemeValue, ITitleSizeValue } from "../section.model"
import getTextContent from "./getTextContent"
import { TitleSizeContext, getSizeStyles } from "../TitleSizeContext"
import { elementData, formatMultilineText, getCustomStyle } from "../utils"


export enum ICardVariant {
	Default = "default",
	Contained = "contained-card",
	FeeContent = "free-content",
	Horizontal = "horizontal",
	ButtonBottomLeft = "button-bottom-left",
	ButtonBottomRight= "button-bottom-right",
	CardWithList = "card-with-list",
	CardWithListHorizontal = "card-with-list-horizontal"
}


interface IAdditionalContentProps {
	hideListDecorator?: boolean
}


interface ImediaContainerProps {
	height?: number
}


interface IContainerProps {
	shadow?: boolean
	slug: string
}


interface IWrapperProps {
	slug?: string
	shadow?: boolean
}


const Container = styled.div<IContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 30px 0px 0;
	border-radius: 6px;
	content-visibility: initial;

	${({ shadow }) =>
		shadow &&
		css`
			box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
		`}

	.gatsby-image-wrapper {
		height: 100%;
		width: 100%;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		margin: 30px 30px 0;
	}
`


const Wrapper = styled.div<IWrapperProps>`
	position: relative;
	display: flex;
	max-width: 100%;

	${({ slug, shadow }) =>
		slug && shadow
			? css`
					margin-bottom: -30px;

					&:hover {
						${Container} {
							box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.2);
						}

						button[data-variant="secondary"] {
							background-color: ${themeCommon.color.darkPurple};
							color: ${themeCommon.color.white};
						}
					}
			  `
			: css`
					${mediaBreakpoint(BreakPoints.SM)} {
						margin: 30px;
					}
			  `};
`

const WrapperLink  = styled.div<IWrapperProps>`
	position: relative;
	display: flex;
	max-width: 100%;
	margin: 0px;
`

const getDescriptionStyles = (theme?: IThemeValue) => css`
	font-size: 1.6rem;
	line-height: 1.2;
	color: ${getColorStyles(theme)};
	${mediaBreakpoint(BreakPoints.SM)} {
		font-size: 2rem;
	}
`
const buttonContainerStyles = css`
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
	width: 100%;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: fit-content;
	}
	
`
const Anchor = styled.a`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	text-decoration: none;
	z-index: 2;
`
const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
		video, iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.2);
		}
`
const DateContainer = styled.div`
`
const MediaContainer = styled.div<ImediaContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	height: ${({ height }) => (height ? `${height}px` : "initial")};
	flex-shrink: 0;

	&:empty {
		display: none;
	}
`
const Description = styled.p`
`
export const Category = styled.div`
	font-size: 1.6rem;
	line-height: 1.5;
	color: #696969;
	margin: 0 0 6px;
`
const AdditionalContent = styled.div<IAdditionalContentProps>`
	margin: 1.8rem 0 0;
	ul {
		margin: 0 0 35px;
	}
	li {
		display: flex;
		align-items: center;
		list-style: none;
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-weight: 600;
		margin: 0 0 24px;

		${({ hideListDecorator }) =>
		!hideListDecorator &&
			`&:before {
			content: "";
			width: 36px;
			height: 36px;
			min-height: 36px;
			min-width: 36px;
			background: url("${CheckMarkRoundedIcon}") no-repeat center center;
			background-size: cover;
			border-radius: 50%;
			margin: 0 16px 0 0;
			align-self: flex-start;
			${mediaBreakpoint(BreakPoints.SM)} {
				align-self: initial;
			}
		}`}

		img {
			margin-right: ${themeCommon.spacing(2.5)};
		}
	}
	p {
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: inherit;
		margin: 0 0 14px;
		a {
			color: inherit;
			text-decoration: none;
			:hover {
				font-weight: 800;
			}
		}
	}
`
const titleStyles = css`
	width: 100%;
`
const CompanyLogo = styled.div`
	width: 9.5rem;
	margin-top: -1rem;
`
const List = styled.ol `
	list-style-type: none;
  	margin-left: 0;
	  li {
		counter-increment: customlistcounter;
	  }
	  li:before {
		content: counter(customlistcounter) " ";
		font-weight: bold;
		float: left;
		font-size: 1.8rem;
		font-weight: 800;
		line-height: 5rem;
		color: #9450FF;
	  }
`
const ListCard = styled.div`
	display: flex;
`
const TopContainer = styled.div `
	display: block;
`
const BottomContent = styled.div`
	display:block;
`
const TextContainer = styled.div `
	align-self: center;
	padding-left: 2.4rem;
`
const SingUpBottom = styled.div `
	display: contents;
	width: 100%;
    justify-content: space-between;
	align-items: end;
	p {
		font-size: 1.6rem;
		line-height:2rem;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		display: flex;
		p {
			font-size: 2rem;
		}
	}
`
const CardTop = styled.div `
	display: flex;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: row;
	}
`
const CardText = styled.div `
	display: grid;
`
const VideoContainer = styled.div `
	height: 220px;
	${mediaBreakpoint(BreakPoints.SM)} {
		height: 440px;
	}
`
const TopContent = styled.div `
	display: flex;
	width: 100%;
`
const getContentStyles = (alignment: ICardAlignment, variant: ICardVariant) => {
	switch (alignment) {
	case "left":
		return css`
				text-align: left;
				align-items: flex-start;
				${variant === "horizontal" &&
				`
                    text-align: center;
                    align-items: center;
                    ${mediaBreakpoint(BreakPoints.SM)} {
                        margin-left: 2rem;
                    }
                `}
				${mediaBreakpoint(BreakPoints.SM)} {
					${variant === "horizontal" &&
					`
                        text-align: left;
                        align-items: flex-start;
                    `}
				}
			`
	case "right":
		return css`
				text-align: right;
				align-items: flex-end;
				${variant === "horizontal" &&
				`
                    margin-right: 2rem;
                    text-align: center;
                    align-items: center;
                `}
				${mediaBreakpoint(BreakPoints.SM)} {
					${variant === "horizontal" &&
					`
                        text-align: right;
                        align-items: flex-end;
                    `}
				}
			`
	case "center":
	default:
		return css`
				text-align: center;
				align-items: center;
			`
	}
}
const getContainerStyles = (alignment: ICardAlignment) => {
	switch (alignment) {
	case "left":
		return css`
				align-items: flex-start;
			`
	case "right":
		return css`
				align-items: flex-end;
			`
	case "center":
	default:
		return css`
				align-items: center;
			`
	}
}
const getVariantStyles = (variant: ICardVariant) => {
	switch (variant) {
	case ICardVariant.Contained: {
		return css`
				display: flex;
				flex-direction: row;
				align-items: stretch;
				margin: 30px -36px;
				${mediaBreakpoint(BreakPoints.SM)} {
					margin: 30px;
				}
				${MediaContainer} {
					margin: 0;
					width: 46%;
				}
				${Content} {
					width: 44%;
					padding: 24px;
					background: #fff;
					${H5} {
						color: #000;
					}
					${Description} {
						color: #000;
						font-size: 1.6rem;
						line-height: 2.4rem;
					}
					${ButtonBase} {
						color: var(--main-color);
						font-size: 1.6rem;
					}
				}
			`
	}
	case ICardVariant.FeeContent: {
		return css`
				${Content} {
					justify-content: flex-start;
				}
			`
	}
	case ICardVariant.Horizontal: {
		return css`
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				${MediaContainer} {
					align-items: center;
				}
				${mediaBreakpoint(BreakPoints.SM)} {
					flex-direction: row;
					justify-content: flex-start;
					${MediaContainer} {
						align-items: initial;
					}
				}
			`
	}
	case ICardVariant.ButtonBottomLeft: {
		return css`
				padding: 2rem 1rem;
				${MediaContainer} {
					align-items: center;
					width:50%;
					order:1;
				}
				${DateContainer} {
					order:2;
				}				
				${Description} {
					font-size: 1.6rem;
				}
				${Content} {
					width:100%;
				}
				${mediaBreakpoint(BreakPoints.SM)} {
					padding: 3rem 5rem;
					flex-direction: column;
					justify-content: flex-start;
					${MediaContainer} {
						align-items: initial;
						order:2;
						width:150px;
					}
					${DateContainer} {
						order:1;
					}	
				}
			`
	}
	case ICardVariant.ButtonBottomRight: {
		return css`
				padding: 2rem 1rem;
				${MediaContainer} {
					align-items: center;
					width:50%;
					order:1;
				}
				${DateContainer} {
					order:2;
				}				
				${Description} {
					font-size: 1.6rem;
				}
				${Content} {
					width:100%;
				}
				${mediaBreakpoint(BreakPoints.SM)} {
					padding: 3rem 5rem;
					flex-direction: column;
					justify-content: flex-start;
					${MediaContainer} {
						align-items: initial;
						order:2;
						width:150px;
					}
					${DateContainer} {
						order:1;
					}	
				}
			`
	}
	case ICardVariant.CardWithList: {
		return css`
				${MediaContainer} {
					display: none;
				}
				${TextContainer} {
					padding-left: 0;
				}
				${Category} {
					font-size: 1.2rem;
					font-weight: bold;
					color: #9450FF;
					line-height: 2.4rem;
					text-transform: uppercase;
				}
				${Description} {
					font-size: 1.6rem;
					display: none;
				}
				${AdditionalContent} {
					p {
						color: #00000090;
					}
				}
				${Content} {
					padding: 3rem;
				}
				${ListCard} {
					flex-direction: column;
					width: 220px;
				}
				${mediaBreakpoint(BreakPoints.MD)} {
					${MediaContainer} {
						display: block;
					}
					${TextContainer} {
						padding-left: 2.4rem;
					}
					${Description} {
						display: block;
					}
					${ListCard} {
						width: 100%;
					}
				}
			`
	}
	case ICardVariant.CardWithListHorizontal: {
		return css`
				${ListCard} {
					display: flex;
					flex-direction: column;
				}
				${TextContainer} {
					padding-left: 0;
				}
				${Category} {
					font-size: 1.2rem;
					font-weight: bold;
					color: #9450FF;
					line-height: 2.4rem;
					text-transform: uppercase;
				}
				${Description} {
					font-size: 1.6rem;
					display: none;
				}
				${AdditionalContent} {
					p {
						color: #00000090;
					}
				}
				${Content} {
					padding: 3.2rem;
					width: 100%;
					height: auto;
				}
				${TopContainer} {
					width: 100%;
				}
				${BottomContent} {
					padding-left: 0;
					padding-top: 0;
				}
				${MediaContainer} {
					display: none;
				}
				${mediaBreakpoint(BreakPoints.MD)} {
					${ListCard} {
						display: flex;
						flex-direction: row;
					}
					${MediaContainer} {
						display: block;
					}
					${TopContainer} {
						width: 50%;
					}
					${Content} {
						padding: 3.2rem;
						min-width: 792px;
						max-height: 328px;
					}
					${TextContainer} {
						padding-left: 2.4rem;
					}
					${Description} {
						display: block;
					}
					${BottomContent} {
						padding-left: 60px;
						padding-top: 30px;
					}
					[data-element="button"] {
						margin-top: -40px;
					}
				}
				
			`
	}
	case ICardVariant.Default:
	default:
		return css``
	}
}

const getMediaStyles = (variant: ICardVariant) => {
	switch (variant) {
	case ICardVariant.Contained:
		return css`
				height: 100%;
				flex-grow: 1;
			`
	case ICardVariant.Default:
	default:
		return css``
	}
}

const getImageStyles = (size?: ICardImageSize) => {
	// TODO - this could be improved by making it more consistent
	// e.g. making them all percent values OR allowing puting custom value in contentful
	switch (size) {
	case "bulletpoint":
		return css `
			width: 30px;
		`
	case "full-size":
		return css`
				width: 100%;
				margin-bottom: 4rem;
			`
	case "large":
		return css`
				width: 300px;
				margin-bottom: 2rem;
			`
	case "medium-large":
		return css`
				width: 250px;
				margin-bottom: 2rem;
			`
	case "medium":
		return css`
				width: 200px;
				margin-bottom: 2rem;
			`
	case "small-medium":
		return css`
				width: 180px;
				margin-bottom: 2rem;
			`
	case "small":
		return css`
				width: 78px;
				min-width: 78px;
				margin-bottom: 2rem;
			`
	default: {
		return css`
				width: 100px;
				margin-bottom: 2rem;
			`
		}
	}
}

const getCTAStyles = (hasCTA: boolean) =>
	hasCTA
		? css`
				${Content} {
					justify-content: space-between;
				}
		  `
		: css`
				${Content} {
					justify-content: flex-start;
				}
		  `
const headerStyles = (variant: ICardVariant) => {
	return variant === "horizontal"
		? css`
				margin: 0;
		  `
		: null
}

export type ICardImageSize = "small" | "small-medium" | "medium" | "medium-large" | "large" | "full-size" | "bulletpoint"
export type ICardAlignment = "center" | "left" | "right"


export interface CardProps {
	id: string
	contentful_id: string
	description: {
		description: string
	}
	title: string
	image: any
	buttons?: ButtonProps[]
	imageSize?: ICardImageSize
	imageHeight?: number
	titleSize?: ITitleSizeValue
	cardCss?: SerializedStyles
	cardContainerCss?: SerializedStyles
	alignment: ICardAlignment
	alternativeContent?: IJsonContentfulField
	variant: ICardVariant | null
	category: CategoryProps | null
	titleLevel?: string
	content: {
		childMarkdownRemark: {
			html: string
		}
	} | null
	contentHasCustomImages?: boolean
	shadow?: boolean
	url?: {
		slug: string
	}
	link?: {
		url: string
	}
	customSpacing?: IJsonContentfulField
	dateAndTime: string
	companyLogo: any
	embedYoutubeId: string
	cardColorScheme?: IColorScheme
	list?: IListItemProps[]
}


const Card: React.FC<CardProps> = ({
	contentful_id,
	title,
	image,
	description,
	buttons = [],
	imageSize,
	imageHeight,
	cardCss,
	cardContainerCss,
	alignment,
	alternativeContent,
	titleSize: titleSizeQuery,
	variant = ICardVariant.Default,
	category,
	content,
	contentHasCustomImages,
	titleLevel,
	shadow,
	url,
	customSpacing,
	dateAndTime,
	companyLogo,
	embedYoutubeId,
	cardColorScheme,
	link,
	list = []
}) => {
	const isButtonBottomLeft = variant === "button-bottom-left"
	const isButtonBottomRight = variant === "button-bottom-right"
	const isCardWithList = variant === "card-with-list"
	const isCardWithListHorizontal = variant === "card-with-list-horizontal"
	const cardDescription = description ? description.description : ""


	const titleSize = titleSizeQuery || "extra-small"
	const { hash } = useLocation()
	// it is possible to set the card's content dynamically based on the hash in URL
	const textContent = getTextContent(hash, alternativeContent, title, cardDescription)
	const sectionTheme = useSectionTheme()
	const slug = url?.slug


	const WrapperProvider = ({ children }) =>
		slug ? (
			<Wrapper slug={slug} shadow={shadow} css={cardContainerCss} data-element="a-wrapper">
				{children}
			</Wrapper>
		) : link ? (
			<WrapperLink slug={slug} shadow={shadow} css={cardContainerCss} data-element="a-wrapper">
				{children}
			</WrapperLink>
		) : (
			children
		)


	return (
		<TitleSizeContext.Provider value={titleSize}>
			<WrapperProvider>
				{isButtonBottomLeft || isButtonBottomRight ? (
					<Container
						id={contentful_id}
						css={[
							cardCss,
							getContainerStyles(alignment),
							getCTAStyles(Boolean(buttons)),
							getVariantStyles(variant),
							getCustomStyle(customSpacing),
							getColorScheme(cardColorScheme)
						]}
						shadow={shadow}
						slug={slug}
						data-element="card-container"
					>
						{isButtonBottomLeft ?
							(<Content css={getContentStyles(alignment, variant)}>
								{category ? <Category data-element="card-category">{category.title}</Category> : null}
								<SpanH5
									css={[headerStyles(variant), getSizeStyles(titleSize), titleStyles]}
									as={titleLevel || "h2"}
									data-element={elementData.cardTitle}
									sectionTheme={sectionTheme}
									className={titleLevel ?? "show-title" }
									dangerouslySetInnerHTML={{ __html: textContent.title }}
								/>
								<Description css={getDescriptionStyles(sectionTheme)}>
									{formatMultilineText(textContent.description)}
								</Description>
								{content ? (
									<AdditionalContent
										hideListDecorator={contentHasCustomImages}
										dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
									/>
								) : null}
								<SingUpBottom>
									<DateContainer>
										{dateAndTime? <p>{dateAndTime}</p> : null }
										{(buttons || []).map(button => {
											const { id } = button
											return (
												<div key={id} css={buttonContainerStyles}>
													<Button {...button} />
												</div>
											)
										})}
									</DateContainer>
									<MediaContainer css={getImageStyles(imageSize)} height={imageHeight} data-element="media-container">
										{image ? <Media css={getMediaStyles(variant)} {...image} /> : null}
									</MediaContainer>
								</SingUpBottom>
							</Content>) : null }
						{isButtonBottomRight ?
							(<Content css={getContentStyles(alignment, variant)}>
								{category ? <Category data-element="card-category">{category.title}</Category> : null}
								<CardTop>
									<CardText>
										<SpanH5
											css={[headerStyles(variant), getSizeStyles(titleSize), titleStyles]}
											as={titleLevel || "h2"}
											data-element={elementData.cardTitle}
											sectionTheme={sectionTheme}
											className={titleLevel ?? "show-title" }
											dangerouslySetInnerHTML={{ __html: textContent.title }}
										/>
										<Description css={getDescriptionStyles(sectionTheme)}>
											{formatMultilineText(textContent.description)}
										</Description>
									</CardText>
									<MediaContainer css={getImageStyles(imageSize)} height={imageHeight} data-element="media-container">
										{image ? <Media css={getMediaStyles(variant)} {...image} /> : null}
									</MediaContainer>
								</CardTop>
								{content ? (
									<AdditionalContent
										hideListDecorator={contentHasCustomImages}
										dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
									/>
								) : null}
								<SingUpBottom>
									{dateAndTime? <p>{dateAndTime}</p> : null }
									<DateContainer>
										{(buttons || []).map(button => {
											const { id } = button
											return (
												<div key={id} css={buttonContainerStyles}>
													<Button {...button} />
												</div>
											)
										})}
									</DateContainer>
								</SingUpBottom>
							</Content>) : 
							null }


					</Container>
				) : isCardWithList || isCardWithListHorizontal ? (
					<Container
					id={contentful_id}
					css={[
						cardCss,
						getContainerStyles(alignment),
						getCTAStyles(Boolean(buttons)),
						getVariantStyles(variant),
						getCustomStyle(customSpacing),
						getColorScheme(cardColorScheme)
					]}
					shadow={shadow}
					slug={slug}
					data-element="card-container"
				>
					
					<Content css={getContentStyles(alignment, variant)}>
					<ListCard>
						<TopContainer>
							<TopContent>
								<MediaContainer css={getImageStyles(imageSize)} height={imageHeight} data-element="media-container">
									{image ? <Media css={getMediaStyles(variant)} {...image} /> : null}
								</MediaContainer>
								<TextContainer>
								{category ? <Category data-element="card-category">{category.title}</Category> : null}
								{ title && <H3
									css={[headerStyles(variant), getSizeStyles(titleSize), titleStyles]}
									as={titleLevel || "span"}
									data-element={elementData.cardTitle}
									sectionTheme={sectionTheme}
									className={titleLevel ?? "show-title" }
									dangerouslySetInnerHTML={{ __html: textContent.title }}
								/> }
								</TextContainer>
							</TopContent>
							{ description &&
							<Description css={getDescriptionStyles(sectionTheme)} data-element="description">
								{formatMultilineText(textContent.description)}
							</Description> }
						</TopContainer>
						<BottomContent>
							{list ? <List>
							{(list || []).map(listitem => {
								return (
										<ListItem key={listitem.id} {...listitem} />
								)
							})}
							</List> : null }
							{content ? (
								<AdditionalContent
									data-element="additional-content"
									hideListDecorator={contentHasCustomImages}
									dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
								/>
							) : null}
						</BottomContent>
					</ListCard>
						{(buttons || []).map(button => {
							const { id } = button
							return (
								<div key={id} css={buttonContainerStyles} data-element="button">
									<Button {...button} />
								</div>
							)
						})}
					</Content>
				</Container>
					) : (
						<Container
							id={contentful_id}
							css={[
								cardCss,
								getContainerStyles(alignment),
								getCTAStyles(Boolean(buttons)),
								getVariantStyles(variant),
								getCustomStyle(customSpacing),
								getColorScheme(cardColorScheme)
							]}
							shadow={shadow}
							slug={slug}
							data-element="card-container"
						>
							<MediaContainer css={getImageStyles(imageSize)} height={imageHeight} data-element="media-container">
								{image ? <Media css={getMediaStyles(variant)} {...image} /> : null}
							</MediaContainer>
							<Content css={getContentStyles(alignment, variant)}>
								{category ? <Category data-element="card-category">{category.title}</Category> : null}
								{ title && <H3
									css={[headerStyles(variant), getSizeStyles(titleSize), titleStyles]}
									as={titleLevel || "span"}
									data-element={elementData.cardTitle}
									sectionTheme={sectionTheme}
									className={titleLevel ?? "show-title" }
									dangerouslySetInnerHTML={{ __html: textContent.title }}
								/> }
								{ description &&
							<Description css={getDescriptionStyles(sectionTheme)} data-element="description">
								{formatMultilineText(textContent.description)}
							</Description> }
								{content ? (
									<AdditionalContent
										data-element="additional-content"
										hideListDecorator={contentHasCustomImages}
										dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
									/>
								) : null}
								{ embedYoutubeId ? (
									<VideoContainer>	
										<iframe
											style={{
												zIndex: 5,
											}}
											src={`https://www.youtube.com/embed/${embedYoutubeId}`}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
										/>
									</VideoContainer>
							 ): null}
								{companyLogo ? <CompanyLogo><Media {...companyLogo} /></CompanyLogo> : null}
								{(buttons || []).map(button => {
									const { id } = button
									return (
										<div key={id} css={buttonContainerStyles}>
											<Button {...button} />
										</div>
									)
								})}
							</Content>
						</Container>) }
				{slug && <Anchor href={`/${slug}/`} />}
				{link && <Anchor href={link.url}/>}
			</WrapperProvider>
		</TitleSizeContext.Provider>
	)
}


export const query = graphql`
	fragment CardLinkUrl on ContentfulLinksUrl {
		__typename
		id
		url
	}

	fragment Card on ContentfulSectionCards {
		__typename
		contentful_id
		id
		dateAndTime
		title
		embedYoutubeId
		description {
			description
		}
		buttons {
			...Button
		}
		image {
			...MediaOriginal
		}
		companyLogo {
			...Media160
		}
		cardColorScheme {
			...ColorScheme
		}
		titleSize
		titleLevel
		imageSize
		imageHeight
		alignment
		variant
		category {
			...Category
		}
		alternativeContent {
			internal {
				content
			}
		}
		content {
			childMarkdownRemark {
				html
			}
		}
		contentHasCustomImages
		shadow
		url {
			slug
		}
		link {
			... on ContentfulLinksUrl {
				url
			}
		}
		customSpacing {
			internal {
				content
			}
		}
		list {
			...ListItem
		}
	}
`


export default React.memo(Card)